var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('TitleBasic',{attrs:{"title":_vm.title}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-container',{staticClass:"d-flex pt-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-magnify","label":_vm.$t('GENERAL_INTERFACE_SEARCH_TEXT'),"value":_vm.search,"single-line":"","hide-details":""},on:{"input":_vm.searchResolve}})],1)],1)],1),(_vm.error)?_c('v-container',{attrs:{"fluid":""}},[_c('v-alert',{attrs:{"colored-border":"","border":"top","elevation":"2","type":"error"}},[_vm._v(_vm._s(_vm.$t(_vm.error)))])],1):(_vm.hasItems && !_vm.loading)?[_c('v-list',{staticClass:"py-0",attrs:{"two-line":""}},[_c('v-list-item-group',{attrs:{"multiple":""}},[_vm._l((_vm.items),function(item,index){return [_c('v-list-item',{key:item.id,attrs:{"to":{ name: 'event', params: { id: item.id } }}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.externalId)}}),(!_vm.hasEndpoint)?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.endpoint)}}):_vm._e(),_c('v-list-item-subtitle',[_c('span',[_vm._v(_vm._s(_vm.$t("COMMON_INTERFACE_CREATED_AT_TEXT"))+": "+_vm._s(_vm.getDisplayDateFormat(item.createdAt)))]),(
                      item.lastUpdatedAt &&
                        item.createdAt !== item.lastUpdatedAt
                    )?_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(_vm.$t("COMMON_INTERFACE_LAST_UPDATED_AT_TEXT"))+": "+_vm._s(_vm.getDisplayDateFormat(item.lastUpdatedAt)))]):_vm._e()])],1)],1),_c('v-divider',{key:index})]})],2)],1),_c('v-pagination',{staticClass:"mt-5",attrs:{"length":_vm.itemsLength},on:{"input":_vm.itemsLoadData},model:{value:(_vm.itemsPage),callback:function ($$v) {_vm.itemsPage=$$v},expression:"itemsPage"}})]:(!_vm.loading)?_c('v-container',{attrs:{"fluid":""}},[_c('v-alert',{attrs:{"border":"top","colored-border":"","type":_vm.hasSearchErrors ? 'warning' : 'info',"elevation":"2"}},[_vm._v(" "+_vm._s(_vm.$t("GENERAL_INTERFACE_NO_ENTRIES_TEXT"))+" "),(_vm.loadingSearchErrors)?[_vm._v("... "+_vm._s(_vm.$t("PAGE_ERRORS_SEARCH_CHECKING_ERRORS_TEXT")))]:(_vm.hasSearchErrors)?_c('v-btn',{staticClass:"ml-4",attrs:{"outlined":"","color":"error","to":{
            name: 'errors',
            query: {
              s: this.search || undefined,
              endpoint: this.endpoint || undefined
            }
          }}},[_vm._v(_vm._s(_vm.$t("PAGE_ERRORS_SEARCH_FOUND_ERRORS_TEXT")))]):_vm._e()],2)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }