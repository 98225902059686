
import { Component, Vue } from "vue-property-decorator";
import TitleBasic from "@/components/layout/TitleBasic.vue";
import { debounce } from "debounce";
import { EventOverview } from "@/interfaces/services/event";
import { eventService } from "@/services/event";
import { interfaceService } from "@/services/interface";
import { errorService } from "@/services/error";

@Component({
  components: {
    TitleBasic
  }
})
export default class Main extends Vue {
  loading = true;
  error: string | null = null;
  items: EventOverview[] = [];
  itemsCount = 0;
  itemsPage = 1;
  itemsPerPage = 20;

  search: string | null = null;
  searchResolve = debounce(this.updateSearch, 500);

  searchErrorsCount = 0;
  searchErrorsPage = 1;
  searchErrorsPerPage = 5;
  loadingSearchErrors = false;

  get title() {
    return this.hasEndpoint
      ? `${this.$t("PAGE_TITLE_ENDPOINT_EVENTS_TEXT")} ${this.endpoint}`
      : this.$t("COMMON_INTERFACE_EVENTS_TEXT");
  }

  get itemsLength() {
    return Math.ceil(this.itemsCount / this.itemsPerPage);
  }

  get hasItems() {
    return this.itemsCount > 0;
  }

  get endpoint() {
    const qep = this.$router.currentRoute.query["endpoint"];
    return qep
      ? Array.isArray(qep)
        ? qep.length > 0
          ? qep[0]
          : null
        : qep
      : null;
  }

  get hasEndpoint() {
    return this.endpoint != null;
  }

  get hasSearch() {
    return this.search != null;
  }

  get checkSearchErrors() {
    return this.hasSearch && this.itemsCount === 0;
  }

  get hasSearchErrors() {
    return this.searchErrorsCount > 0;
  }

  created() {
    this.itemsLoadData();
  }

  itemsLoadData() {
    this.error = null;
    this.loading = true;
    eventService
      .list(this.itemsPerPage, this.itemsPage, {
        s: this.search || undefined,
        ep: this.endpoint || undefined
      })
      .then(data => {
        this.items = data.items || [];
        this.itemsCount = data.count;
        this.errorsLoadData();
      })
      .catch(e => (this.error = e.code))
      .finally(() => (this.loading = false));
  }

  errorsLoadData() {
    this.searchErrorsCount = 0;
    if (this.checkSearchErrors) {
      this.loadingSearchErrors = true;
      errorService
        .list(this.searchErrorsPerPage, this.searchErrorsPage, {
          s: this.search ? [this.search] : undefined,
          ep: this.endpoint || undefined
        })
        .then(data => {
          this.searchErrorsCount = data.count;
        })
        .catch(e => (this.error = e.code))
        .finally(() => (this.loadingSearchErrors = false));
    }
  }

  updateSearch(value: string) {
    this.search = value;
    this.itemsLoadData();
  }

  getDisplayDateFormat(date: string) {
    return new Date(date).toLocaleString(interfaceService.getLanguage(), {
      dateStyle: "medium",
      timeStyle: "medium"
    });
  }
}
